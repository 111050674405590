<script setup>
    import PrimevueButton from 'primevue/button';

    const props = defineProps({
        icon: {
            type: String,
            default: null,
        },

        label: {
            type: String,
            default: null,
        },

        iconSize: {
            type: String,
        },
        text: {
          type: Boolean,
          default: false,
        }
    });

    //Return the correct size for the icon
    const size = computed(() => {
        if(props.iconSize){
            return props.iconSize;
        } else if (props.label) {
            return '1.25rem';
        } else {
            return null;
        }
    })
</script>

<template>
    <PrimevueButton :label="props.label" :text="props.text">
        <template v-if="props.icon" #icon="slotProps">
            <Icon :name="props.icon" :class="slotProps.class" :size="size" :width="size" />
        </template>

        <slot />
    </PrimevueButton>
</template>
